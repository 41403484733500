import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { useLax } from 'utils/hooks/lax';
import { Topic, TopicH1, TopicHeading, TopicParagraph } from 'components';
import {
    TeamBody,
    TeamData,
    TeamDataBody,
    TeamDataItem,
    TeamMasonry,
    TeamMasonryItem,
    TeamMasonryBio,
    TeamMasonryMember,
    TeamCooperate,
} from 'components/team-page/team-page';

export default function TeamPage(): JSX.Element {
    const data = useStaticQuery(imgQuery);

    useLax();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Phase One Karma — Team.</title>
                <meta name="description"
                      content="Loio — a shortcut to perfect legal documents."
                />
            </Helmet>

            <TeamBody>
                <Topic>
                    <TopicH1>The founders of ambitions</TopicH1>
                    <TopicParagraph>
                        P1K forms a family-like community of technical engineers, managers, and creatives.
                        Our goal is to produce smart AI-driven innovations to optimize daily routine for
                        businesses and people globally.
                    </TopicParagraph>
                    <TopicParagraph>
                        Every business has a leading guru. Now, we are lucky, because we have five! We present
                        our board members — people who stand behind P1K’s progress.
                    </TopicParagraph>
                </Topic>


                <TeamMasonry>
                    <TeamMasonryItem
                        data-aos="fade-right"
                        data-aos-offset="200"
                        data-aos-delay={60}
                    >
                        <TeamMasonryMember
                            name="Bogdan Pol"
                            position="Chairman of the Board"
                            image={data.memberBogdan.childImageSharp.fluid}
                            linkedin="https://www.linkedin.com/in/bogdan-pol-699263182/"
                        />
                        <TeamMasonryBio>
                            The true spirit and soul of the company. The first co-founder without whom Phase One Karma
                            would never exist. The person who always knows which way the wind blows. It's all about
                            him. His core life ideas are changing the community around and ensuring a better education
                            for entrepreneurs to provide them with more opportunities to succeed.
                        </TeamMasonryBio>
                    </TeamMasonryItem>

                    <TeamMasonryItem
                        data-aos="fade-left"
                        data-aos-offset="200"
                        data-aos-delay={60}
                    >
                        <TeamMasonryMember
                            name="Yehor Melnykov"
                            position="CEO"
                            image={data.memberYehor.childImageSharp.fluid}
                            linkedin="https://www.linkedin.com/in/megor/"
                        />
                        <TeamMasonryBio>
                            Yehor is the mastermind of the latest technology. Нe possesses strong technical skills in
                            creating projects of any complexity. Over the years Yehor worked on creating plagiarism
                            checker product, which is currently used in many countries in Europe and America. Finding
                            the best, most profitable solution for the company is the main challenge for him. His
                            well-designed project strategies provide a clear understanding and vision of the company's
                            goals. Unresolved problem is not a problem, but the next challenge for him and his team.
                        </TeamMasonryBio>
                    </TeamMasonryItem>

                    <TeamMasonryItem
                        data-aos="fade-right"
                        data-aos-offset="200"
                        data-aos-delay={60}
                    >
                        <TeamMasonryMember
                            name="Dmitriy Demyanenko"
                            position="CFO"
                            image={data.memberDmitriy.childImageSharp.fluid}
                            linkedin="https://www.linkedin.com/in/dmitriy-d-1862b338/"
                        />
                        <TeamMasonryBio>
                            As anyone who is dealing with high-level financial issues, Dmitriy is an overly serious
                            person with extreme attention to details and high level of responsibility. However, after
                            closing all spreadsheets and turning off his laptop, he magically turns into a lively
                            person whose hilarious jokes entertain everyone around.
                        </TeamMasonryBio>
                    </TeamMasonryItem>

                    <TeamCooperate
                        data-aos="fade-down"
                        data-aos-offset="200"
                        data-aos-delay={60}
                    />
                </TeamMasonry>


                <TeamData>
                    <TeamDataBody>
                        <TeamDataItem>
                            <h4>2014</h4>
                            <p>founded</p>
                        </TeamDataItem>
                        <TeamDataItem>
                            <h4>53</h4>
                            <p>members</p>
                        </TeamDataItem>
                        <TeamDataItem>
                            <h4>1,2 m</h4>
                            <p>users globally</p>
                        </TeamDataItem>
                    </TeamDataBody>
                </TeamData>


                <Topic>
                    <TopicHeading>Solving modern digital issues</TopicHeading>
                    <TopicParagraph>
                        We believe P1K’s fortune rests on gathering information to use it to solve modern digital
                        issues. That’s why we have a big Research and Development team to examine and produce new,
                        exciting, and sometimes scary ideas. The R&D explores the technological capacities of AI
                        technology, Machine Learning, and spices them up with NLP and NLU. Then, together, we
                        choose the best approach to start working on the new product.
                    </TopicParagraph>
                    <TopicParagraph>
                        P1K's team stays in sync during the work process. Our successful projects are born as
                        a result of united action by technicians, developers, and creative co-workers.
                    </TopicParagraph>
                </Topic>
            </TeamBody>
        </>
    );
}

export const imgQuery = graphql`
    query {
        memberBogdan: file(relativePath: { eq: "team/bogdan-pol.png" }) {
            childImageSharp {
                fluid(maxWidth: 488, srcSetBreakpoints: [488], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        memberYehor: file(relativePath: { eq: "team/yehor-melnykov.png" }) {
            childImageSharp {
                fluid(maxWidth: 488, srcSetBreakpoints: [488], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        memberDmitriy: file(relativePath: { eq: "team/dmitriy-demyanenko.png" }) {
            childImageSharp {
                fluid(maxWidth: 488, srcSetBreakpoints: [488], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;
